import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { shallow } from 'zustand/shallow';

import { instanceWithRocket } from '@Config/axios';

// Context
import useAuth from '@Auth/userAuth';

// store
import { storeBuyTank, globalStore, storeRemainingTank, storeModalTank } from '@Store/global';

// services
import { CreateCustomer } from '@Service/conekta.service';
import { BuyTanks } from '@Service/tanks.service';

// Icons
import { FaRegTimesCircle, FaInfoCircle } from 'react-icons/fa';

// styles
import styles from '@Sass/pages/checkout.module.scss';


const Checkout = () => {
  const { setLoading } = useAuth();
  const [userData, setUserData] = useState(null);
  const [userBilling, setUserBilling] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [style, setStyle] = useState(styles.verdatos);
  const { setTanquesRestante } = storeRemainingTank(
    (state) => ({
      setTanquesRestante: state.setTanquesRestante,
    }),
    shallow
  );
  const { tanquesData } = storeBuyTank(
    (state) => ({
      tanquesData: state.tanquesData,
    }),
    shallow
  );
  const { setAlert, setMessage } = globalStore(
    (state) => ({
      setAlert: state.setAlert,
      setMessage: state.setMessage,
    }),
    shallow
  );

  const togglePersonales = () => {
    setIsOpen((isOpen) => !isOpen);
    if (isOpen === true) setStyle(styles.verdatos);
        else setStyle(styles.ocultardatos);
};

  const { storeTankModal } = storeModalTank(
    (state) => ({
      storeTankModal: state.storeTankModal,
    }),
    shallow
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    formState: { errors: errorsbilling },
  } = useForm();

  const headersUrlencoded = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

// Obtener los campos del formulario de la API
useEffect(() => {
  const fetchData = async () => {
    try {
      const response =  await instanceWithRocket.get(`checkout/get_data`, headersUrlencoded);
      setUserData(response.data.userdata);
      setUserBilling(response.data.userbilling);
      
    
    } catch (error) {
      console.log('Exception Error: ', error);
      return error;
    }
  };

  fetchData();
}, []);

useEffect(() => {
  if (userData) {
    setValue('name', userData.name);
    setValue('lastname', userData.lastname);
    setValue('email', userData.email);
    setValue('country', userData.country);
    setValue('street', userData.street);
    setValue('num_ext', userData.num_ext);
    setValue('num_int', userData.num_int);
    setValue('city', userData.city);
    setValue('zipcode', userData.zipcode);
    setValue('phone', userData.phone);
    
  }

  if (userBilling) {
    setValue1('company', userBilling.company);
    setValue1('businessname', userBilling.businessname);
    setValue1('email', userBilling.email);
    setValue1('rfc', userBilling.rfc);
    setValue1('address', userBilling.address);
    setValue1('numExt', userBilling.numExt);
    setValue1('numInt', userBilling.numInt);
    setValue1('location', userBilling.location);
    setValue1('postalCode', userBilling.postalCode);
    setValue1('phone', userBilling.phone);
    
  }
}, [userData]);


  const history = useHistory();
  const location = useLocation();

  const [modal, setModal] = useState(false);
  const [modalsuccess, setModalSuccess] = useState(false);
  const [pay, setPay] = useState(false);
  const [user, setUser] = useState();

  const handleConektaSuccess = async (jwt, user) => {
    const customer = await CreateCustomer(jwt, user);
    if (customer.code === 0) {
      const buy = await BuyTanks({ customer: customer.data, token: jwt, user, pack: tanquesData });
      if (buy.code === 0) {
        setLoading(false);
        setTanquesRestante({
          remainingTanks: buy.data.tanques_restantes,
        });
        history.push({ pathname: '/gracias', from: location });
      } else if (buy.code < 0) {
        setLoading(false);
        setAlert(true);
        setMessage('Ocurrio un error al procesar el pago');
      }
    } else if (customer.code < 0) {
      setLoading(false);
      setAlert(true);
      setMessage('Ocurrio un error al procesar la tarjeta');
    }
  };

  const handleConektaError = (error) => {
    setAlert(true);
    setMessage('Error al generar cliente');
    console.log('Error al procesar el pago:', error);
    setLoading(false);
  };

  const handleScript = () => {
    setModal(true);
    setTimeout(() => {
      const config = {
        targetIFrame: '#conektaIframeContainer',
        publicKey: process.env.REACT_APP_CONEKTA_APIKEY_PUBLIC,
        locale: 'es',
      };
      const callbacks = {
        onCreateTokenSucceeded: function (token) {
          setModal(false);
          setLoading(true);
          handleConektaSuccess(token, user);
        },

        onCreateTokenError: function (error) {
          setModal(false);
          setLoading(true);
          handleConektaError(error);
        },
      };
      window.ConektaCheckoutComponents.Card(
        {
          config,
          callbacks,
          allowTokenization: true,
        },
        3000
      );
    });
  };

  const headersJson = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const onSubmitPersonales = async data => {

    const send = JSON.stringify(data);

    try {
      const response = await instanceWithRocket.post(
        `checkout/personal_data`,
        send,
        headersJson
      );
      const { data } = response.data;
      setModalSuccess(true);
      return { data: data, completed: data.completed, code: 0 };
    } catch (error) {
      const { response } = error;
      return { messageError: response.data.error, status: response.status, code: -1 };
    }
    
  };

  const onSubmitFactura = async data => {

    const send = JSON.stringify(data);

    try {
      const response = await instanceWithRocket.post(
        `checkout/billing_data`,
        send,
        headersJson
      );
      const { data } = response.data;
      setModalSuccess(true);
      return { data: data, completed: data.completed, code: 0 };
    } catch (error) {
      const { response } = error;
      return { messageError: response.data.error, status: response.status, code: -1 };
    }
   
  };

  return (
    <section>
      <div className={styles.Checkout}>
        <div className={styles.container}>
          <h2 className={styles.titlePage}>Checkout</h2>
            <div className={styles.formContent}>
              <div className={styles.form1}>
                <div className={style}>
                  <h2>DATOS PERSONALES</h2>
                </div>
                <form className={styles.form} onSubmit={handleSubmit(onSubmitPersonales)}>
                  <div className={styles.formControl}>
                    <span className={styles.formInputs}>
                    <label htmlFor="name" className={styles.formLabel}>
                      Nombre<small>*</small>
                    </label>
                    <input
                      type="text"
                      placeholder="Nombre"
                      id="name"
                      className={styles.inputText}
                      {...register('name', { required: true })}
                    />
                    {errors.name && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>Ingrese su nombre</span>
                      </span>
                    )}
                    </span>

                    <span className={styles.formInputs}>
                    <label htmlFor="lastname" className={styles.formLabel}>
                      Apellidos<small>*</small>
                    </label>
                    <input
                      type="text"
                      placeholder="Apellidos"
                      id="lastname"
                      className={styles.inputText}
                      {...register('lastname', { required: true })}
                    />
                    {errors.lastname && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>Ingrese sus apellidos</span>
                      </span>
                    )}
                    </span>

                    <span className={styles.formInputs}>
                    <label htmlFor="country" className={styles.formLabel}>
                      País/Región <small>*</small>
                    </label>
                      <input
                      type="text"
                      placeholder="País"
                      id="country"
                      name="country"
                      className={styles.inputText}
                      {...register('country', { required: true })}
                    />
                    {errors.country && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>Ingrese su país</span>
                      </span>
                    )}
                    </span>
                  </div>

                  <div className={styles.formControlPlus}>
                    <span className={styles.formInputs}>
                    <label htmlFor="street" className={styles.formLabel}>
                      Calle<small>*</small>
                    </label>
                    <input
                      type="text"
                      placeholder="Nombre de la calle"
                      id="street"
                      name="street"
                      className={styles.inputText}
                      {...register('street', { required: true })}
                    />
                    {errors.street && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>Ingrese su calle</span>
                      </span>
                    )}
                    </span>
                  </div>

                  <div className={styles.formControl}>
                      <span className={styles.formInputs}>
                      <label htmlFor="num_ext" className={styles.formLabel}>
                        Número exterior<small>*</small>
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        id="num_ext"
                        className={styles.inputText}
                        {...register('num_ext', { required: true })}
                      />
                      {errors.num_ext && (
                        <span className={styles.spanError}>
                          <FaInfoCircle /> <span>Ingrese su número exterior</span>
                        </span>
                      )}
                      </span>

                      <span className={styles.formInputs}>
                        <label htmlFor="num_int" className={styles.formLabel}>
                          Número interior<small></small>
                        </label>
                        <input
                          type="text"
                          placeholder=""
                          id="num_int"
                          className={styles.inputText}
                          {...register('num_int', { required: false })}
                        />
                      </span>
                    </div>

                    <div className={styles.formControl}>
                      <span className={styles.formInputs}>
                      <label htmlFor="city" className={styles.formLabel}>
                      Localidad / Ciudad<small>*</small>
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        id="city"
                        className={styles.inputText}
                        {...register('city', { required: true })}
                      />
                      {errors.city && (
                        <span className={styles.spanError}>
                          <FaInfoCircle /> <span>Ingrese su localidad</span>
                        </span>
                      )}
                    </span>

                    <span className={styles.formInputs}>
                      <label htmlFor="zipcode" className={styles.formLabel}>
                        Código postal<small>*</small>
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        id="zipcode"
                        minLength="0"
                        maxLength="5"
                        className={styles.inputText}
                        {...register('zipcode', {
                          required: 'Ingrese su código postal',
                          pattern: {
                            value: /^[0-9]{5}$/,
                            message: 'El código postal debe tener 5 dígitos numéricos',
                          },
                        })}
                      />
                      {errors.zipcode && (
                        <span className={styles.spanError}>
                          <FaInfoCircle /> <span>{errors.zipcode.message}</span>
                        </span>
                      )}
                    </span>
                  </div>

                <div className={styles.formControl}>
                  <span className={styles.formInputs}>
                    <label htmlFor="phone" className={styles.formLabel}>
                      Teléfono<small>*</small>
                    </label>
                    <input
                      type="number"
                      placeholder=""
                      id="phone"
                      minLength="0"
                      maxLength="10"
                      className={styles.inputText}
                      {...register('phone', {
                        required: 'Ingrese su número de teléfono',
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: 'El número de teléfono debe tener 10 dígitos y solo números',
                        },
                      })}
                    />
                    {errors.phone && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>{errors.phone.message}</span>
                      </span>
                    )}
                  </span>

                  <span className={styles.formInputs}>
                    <label htmlFor="email" className={styles.formLabel}>
                      Correo electrónico<small>*</small>
                    </label>
                    <input
                      type="email"
                      placeholder=""
                      id="email"
                      className={styles.inputText}
                      {...register('email', {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i,
                      })}
                    />
                    {errors.email && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>Ingrese su email</span>
                      </span>
                    )}
                  </span>
                </div>
                <button className={styles.compra} type="submit">
                  GUARDAR
                </button>
              </form>
                <div className={style}>
                  <h2>DATOS DE FACTURACIÓN</h2>
                  <span onClick={() => togglePersonales()} >Ver más</span>
                </div>
                {isOpen && (
                <form className={styles.form} onSubmit={handleSubmit1(onSubmitFactura)}>
                  <div className={styles.formControlPlus}>
                    <span className={styles.formInputs}>
                    <label htmlFor="company" className={styles.formLabel}>
                      Nombre de Empresa o Persona Física<small>*</small>
                    </label>
                    <input
                      type="text"
                      placeholder="Empresa"
                      id="company"
                      className={styles.inputText}
                      {...register1('company', { required: true })}
                    />
                    {errorsbilling.company && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>Ingrese su nombre o la empresa</span>
                      </span>
                    )}
                    </span>                    
                  </div>
                  <div className={styles.formControl}>
                  <span className={styles.formInputs}>
                    <label htmlFor="businessname" className={styles.formLabel}>
                      Razón Social<small>*</small>
                    </label>
                    <input
                      type="text"
                      placeholder="Razón Social"
                      id="businessname"
                      className={styles.inputText}
                      {...register1('businessname', { required: true })}
                    />
                    {errorsbilling.businessname && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>Ingrese su Razón Social</span>
                      </span>
                    )}
                    </span>

                    <span className={styles.formInputs}>
                    <label htmlFor="rfc" className={styles.formLabel}>
                      RFC<small>*</small>
                    </label>
                    <input
                      type="text"
                      placeholder="RFC"
                      id="rfc"
                      className={styles.inputText}
                      {...register1('rfc', { required: true })}
                    />
                    {errorsbilling.rfc && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>Ingrese su RFC</span>
                      </span>
                    )}
                    </span>

                    
                  </div>

                  <div className={styles.formControlPlus}>
                    <span className={styles.formInputs}>
                    <label htmlFor="address" className={styles.formLabel}>
                      Dirección Fiscal<small>*</small>
                    </label>
                    <input
                      type="text"
                      placeholder="Dirección Fiscal"
                      id="address"
                      name="address"
                      className={styles.inputText}
                      {...register1('address', { required: true })}
                    />
                    {errorsbilling.address && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>Ingrese su dirección fiscal</span>
                      </span>
                    )}
                    </span>
                  </div>

                  <div className={styles.formControl}>
                      <span className={styles.formInputs}>
                      <label htmlFor="numExt" className={styles.formLabel}>
                        Número exterior<small>*</small>
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        id="numExt"
                        className={styles.inputText}
                        {...register1('numExt', { required: true })}
                      />
                      {errorsbilling.numExt && (
                        <span className={styles.spanError}>
                          <FaInfoCircle /> <span>Ingrese su número exterior</span>
                        </span>
                      )}
                      </span>

                      <span className={styles.formInputs}>
                        <label htmlFor="numInt" className={styles.formLabel}>
                          Número interior<small></small>
                        </label>
                        <input
                          type="text"
                          placeholder=""
                          id="numInt"
                          className={styles.inputText}
                          {...register1('numInt', { required: false })}
                        />
                      </span>
                    </div>

                    <div className={styles.formControl}>
                      <span className={styles.formInputs}>
                      <label htmlFor="location" className={styles.formLabel}>
                      Localidad / Ciudad<small>*</small>
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        id="location"
                        className={styles.inputText}
                        {...register1('location', { required: true })}
                      />
                      {errorsbilling.location && (
                        <span className={styles.spanError}>
                          <FaInfoCircle /> <span>Ingrese su localidad</span>
                        </span>
                      )}
                    </span>

                    <span className={styles.formInputs}>
                      <label htmlFor="postalCode" className={styles.formLabel}>
                        Código postal<small>*</small>
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        id="postalCode"
                        minLength="0"
                        maxLength="5"
                        className={styles.inputText}
                        {...register1('postalCode', {
                          required: 'Ingrese su código postal',
                          pattern: {
                            value: /^[0-9]{5}$/,
                            message: 'El código postal debe tener 5 dígitos numéricos',
                          },
                        })}
                      />
                      {errorsbilling.postalCode && (
                        <span className={styles.spanError}>
                          <FaInfoCircle /> <span>{errorsbilling.postalCode.message}</span>
                        </span>
                      )}
                    </span>
                  </div>

                <div className={styles.formControl}>
                  <span className={styles.formInputs}>
                    <label htmlFor="phone" className={styles.formLabel}>
                      Teléfono<small>*</small>
                    </label>
                    <input
                      type="number"
                      placeholder=""
                      id="phone"
                      minLength="0"
                      maxLength="10"
                      className={styles.inputText}
                      {...register1('phone', {
                        required: 'Ingrese su número de teléfono',
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message: 'El número de teléfono debe tener 10 dígitos y solo números',
                        },
                      })}
                    />
                    {errorsbilling.phone && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>{errors.phone.message}</span>
                      </span>
                    )}
                  </span>

                  <span className={styles.formInputs}>
                    <label htmlFor="email" className={styles.formLabel}>
                      Correo electrónico<small>*</small>
                    </label>
                    <input
                      type="email"
                      placeholder=""
                      id="email"
                      className={styles.inputText}
                      {...register1('email', {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i,
                      })}
                    />
                    {errorsbilling.email && (
                      <span className={styles.spanError}>
                        <FaInfoCircle /> <span>Ingrese su email</span>
                      </span>
                    )}
                  </span>
                </div>
                <button className={styles.compra} type="submit">
                  GUARDAR
                </button>
              </form>
              )} 
              <div>
                <h2>Método de pago</h2>
                <div className={styles.metodoPago} onClick={() => handleScript()}>
                  <a>Pago con Tarjeta de Crédito o Debito</a>
                </div>
                <p className={styles.informacion}>
                  Sus datos personales se utilizarán para respaldar su experiencia en este sitio
                  web, para administrar el acceso a su cuenta y para otros fines descritos en
                  nuestra
                  <a href="/privacidad">[política de privacidad].</a>
                </p>
              </div>   
            </div>
          </div>
        </div>
      </div>

      <div>
        {modal ? (
          <div className={styles.modal}>
            <div className={styles.ModalContent}>
              <div className={styles.closeNav}>
                <FaRegTimesCircle className={styles.iconClose} onClick={() => setModal(false)} />
              </div>
              <div id="conektaIframeContainer" className={styles.conekta}></div>
            </div>
          </div>
        ) : null}
      </div>
      <div>
        {modalsuccess ? (
          <div className={styles.modal}>
            <div className={styles.ModalContent}>
              <div className={styles.closeNav}>
                <FaRegTimesCircle className={styles.iconClose} onClick={() => setModalSuccess(false)} />
              </div>
              <div id="conektaIframeContainer" className={styles.success}>Datos Guardados</div>
            </div>
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default Checkout;
