import { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

// Libs
import anime from 'animejs';

// Components
import Main from '@Components/Main';
import Splash from '@Components/Splash';

const AppRouter = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      const splash = document.getElementById('splashId');
      splash.classList.remove('Splash');
      splash.classList.add('SplashMin');
    }, 1500);

    setTimeout(() => {
      anime({
        targets: '.splashMove',
        opacity: ['9', '0.2'],
        easing: 'spring(1, 100, 10, 0)',
        width: ['320px', '20px'],
        duration: 100,
      });
    }, 2000);

    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <Splash />
      ) : (
        <Router>
          <Switch>
            <Main />
          </Switch>
        </Router>
      )}
    </Fragment>
  );
};

export default AppRouter;
