// COMPONENTS
import PlanetsItem from '@Components/Planets/PlanetsItems';

// IMAGES
import _Astro from '@Assets/images/astro.png';

// STYLES
import '@Components/PlanetContent/PlanetContent.scss';

const Planets = () => {
  return (
    <section className="planets">
      <PlanetsItem />
      <div className="AstroContent">
        <img src={_Astro} alt="" className="astroImage" />
      </div>
    </section>
  );
};

export default Planets;
