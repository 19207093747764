import { useEffect } from 'react';
import { scroller } from 'react-scroll';

// Images
import { BiCaretRightCircle } from 'react-icons/bi';

// Styles
import '@Components/Video/video.scss';

// Images
import _IconDown from '@Assets/images/iconDownFill.png';

// Hooks
import { HomeSlider } from '@Hooks/useFetchWP';

const Index = () => {
  const { sliderInfo } = HomeSlider();

  const scrollingToanimate = () => {
    scroller.scrollTo('bio', {
      duration: 800,
      delay: 100,
      smooth: 'easeIn',
    });
  };

  const previewVideo = (params) => {
    if (params.length > 0) {
      const url = params[0].url;
      const urlObject = new URL(url);
      const videoId = urlObject.searchParams.get('v');
      return (
        <div className="video-container">
          <video
            src={`https://cdn.coverr.co/videos/coverr-rocket-ship-launch-animation-9901/1080p.mp4`}
            frameBorder="0"
            allowFullScreen
            className="coverr"
            autoPlay
          />
          <div className="content">
            <div className="pulse">
              <BiCaretRightCircle className="icon_video" />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="video-container">
        <div className="content">
          <div className="pulse">
            <BiCaretRightCircle className="icon_video" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="video" id="bio">
      {previewVideo(sliderInfo)}
      <div className="videoIconLounch">
        <h2>¿LISTO PARA DESPEGAR?</h2>
        <img src={_IconDown} alt="icondown" onClick={scrollingToanimate} />
      </div>
    </div>
  );
};

export default Index;
