import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

// store
import { jupiterStore } from '@Store/jupiter';
import { lunaStore } from '@Store/luna';

// Hooks
import { useEventJupiter } from '@Hooks/useEventsJupiter';

// Components
import { ScrollToTop } from '@Components/UtilsComponents/ScrollTop';
import { ParagraphPlanet } from '@Components/Atomos/Titles';
import { ValueMarca } from '@Components/Atomos/Inputs/jupiter';
import { SaberMas } from '@Components/Atomos/Buttons';

// Styles
import style from '@Sass/pages/general.module.scss';
import jupiter from '@Sass/pages/jupiter.module.scss';

/* Page 8 */
export const Marca = ({ setPage, setTitle, texts, dataJupiter }) => {
  const { getLuna } = lunaStore(
    (state) => ({
      getLuna: state.getLuna,
    }),
    shallow
  );
  const { setStateAdjetivos } = jupiterStore(
    (state) => ({
      setStateAdjetivos: state.setStateAdjetivos,
    }),
    shallow
  );
  const { jupiterCrearMarca } = useEventJupiter();
  const adjetivos = dataJupiter.adjetivos.length > 0 ? dataJupiter.adjetivos : ['', '', ''];
  const [buttonNext, setButtonNext] = useState(false);
  const [getValueAdjetivos, setValueAdjetivos] = useState(adjetivos);

  useEffect(() => {
    setTitle(texts.titulo_de_la_vista);
  }, [texts]);

  useEffect(() => {
    setStateAdjetivos(getValueAdjetivos);
  }, [getValueAdjetivos]);

  useEffect(() => {
    handleArray();
  }, [getValueAdjetivos]);

  const handleArray = () => {
    const isNotEmpty = getValueAdjetivos.filter((value) => value !== '');
    if (isNotEmpty.length >= 3) {
      setButtonNext(true);
    } else if (isNotEmpty.length < 3) {
      setButtonNext(false);
    }
  };

  const handleSubmmit = async () => {
    const res = await jupiterCrearMarca(getLuna().id, dataJupiter);
    if (res.code === 0) {
      setPage(9);
    }
  };

  return (
    <section className={style.marteQuestions}>
      <ScrollToTop />
      <div>
        <ParagraphPlanet text={texts.descripcion} />
        <SaberMas data={texts} />
      </div>
      <form method="POST">
        <ValueMarca
          getValueAdjetivos={getValueAdjetivos}
          setValueAdjetivos={setValueAdjetivos}
          textDisabled={buttonNext}
        />
        <br></br>
        <div className={style.contentButtons}>
          <button
            type="button"
            onClick={() => handleSubmmit(9)}
            className={buttonNext ? jupiter.btnPlanet : jupiter.btnPlanetOff}
            disabled={buttonNext ? '' : 'disabled'}
          >
            SIGUIENTE
          </button>
        </div>
      </form>
    </section>
  );
};
